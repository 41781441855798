<template>
  <div>
    <step-1 @changeIndex='changeIndex' v-if="index == 1"></step-1>
    <step-2 @changeIndex='changeIndex' v-if="index == 2"></step-2>
    <step-3 @changeIndex='changeIndex' v-if="index == 3"></step-3>
  </div>
</template>

<script>
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
export default {
  components: {
    Step1,
    Step2,
    Step3,
  },
  props: ["index"],
  methods:{
      changeIndex(val){
          this.$emit('changeIndex',val)
      }
  }
};
</script>