<template>
  <div class="login-3" id="step3">
    <i class="el-icon-success"></i>
    <div class="title">
      {{$route.name == 'create'?'注册成功':'修改成功'}}
    </div>
    <div class="tip">
      <span class="active-color">{{ time }}s</span><span>之后自动跳转</span>
    </div>
    <el-button class="login-button" @click="routeChange">完成</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: 5,
      timer: null,
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.time <= 0) {
        clearInterval(this.timer);
        this.routeChange();
      }
      this.time--;
    }, 1000);
  },
  methods: {
    routeChange() {
      if (this.$route.query.token) {
        localStorage.setItem("token", this.$route.query.token);
      }
      if (localStorage.getItem("token")) {
        this.$router.replace("/index");
      } else {
        this.$router.replace("/login");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#step3 {
  text-align: center;
  i {
    color: #a5cf4c;
    font-size: 80px;
  }
  .title {
    font-size: 24px;
    margin: 24px 0 8px;
  }
  .tip {
    font-size: 14px;
    color: rgba(55, 69, 103, 0.65);
  }
  .login-button {
    width: 74px;
    background: #00a0e9;
    color: #fff;
    padding: 5px 0;
    text-align: center;
    font-size: 14px;
    box-shadow: 0 17px 42px -12px rgba(10, 169, 238, 0.3);
    border-radius: 2px;
    margin: 32px 0 25px;
  }
}
</style>