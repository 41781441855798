<template>
  <div id="step1">
    <el-form ref="form" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item label="手机号：" prop="phone" :required="false">
        <phone-template
          id="phone"
          @getPhone="getPhone"
          :isMore="true"
          :disable="disable"
        ></phone-template>
        <div
          class="el-form-item__error"
          v-if="isShow && $route.name == 'create'"
        >
          该手机号已被注册，您可以&nbsp;<router-link to="/" class="active-color"
            >直接登录</router-link
          >
        </div>
        <div
          class="el-form-item__error"
          v-if="isShow && $route.name == 'resetPassword'"
        >
          该手机号未注册，您可以前往&nbsp;<router-link
            to="/create?step=1"
            class="active-color"
            >创建账户</router-link
          >
        </div>
      </el-form-item>
      <el-form-item label="验证：">
        <slider-template
          @phoneVerify="phoneVerify"
          ref="phoneEle"
        ></slider-template>
      </el-form-item>
    </el-form>
    <div class="login-msg">
      <el-checkbox
        v-model="checked"
        @change="checkedChange"
        class="login-checkbox"
      >
        我已阅读并接受
        <router-link class="active-color" to="/login/1">用户协议</router-link>
        和
        <router-link class="active-color" to="/login/1">隐私政策</router-link>
      </el-checkbox>
      <div
        class="login-button login-input-item"
        :class="!click ? 'disable' : ''"
        @click="next"
      >
        下一步
      </div>
      <div class="other-login" v-if="$route.name == 'create'">
        <span class="other-login-txt">使用社交账号注册</span>
        <span class="wx-login-icon"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { PhoneTemplate, SliderTemplate } from "@/components/login/index.js";
import { checkPhone } from "@/api/user";
export default {
  data() {
    return {
      click: false,
      disable: false,
      checked: false,
      ruleForm: {
        phone: "",
      },
      isShow: false,
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3,5,7,9,8]{1}\d{9}$/,
            message: "手机号格式有误",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    $route() {
      this.isShow = false;
      localStorage.removeItem("step1");
    },
  },
  components: {
    PhoneTemplate,
    SliderTemplate,
  },
  methods: {
    next() {
      if (this.click) {
        this.$router.replace({ path: this.$route.path, query: { step: 2 } });
      }
    },
    getPhone(val) {
      this.ruleForm.phone = val;
      // 自己添加的验证要手动删除
      this.isShow = false;
    },
    checkedChange(e) {
      this.click = e && this.disable ? true : false;
    },
    phoneVerify() {
      //手机号验证
      this.$refs.form.validateField("phone", (result) => {
        if (result) {
          this.$refs.phoneEle.reset();
          return;
        }
        if (!result) {
          checkPhone({ phone: this.ruleForm.phone })
            .then((res) => {
              if (res && res.status == 200) {
                if (this.$route.name == "create") {
                  if (res.data.isExist) {
                    //手机号已存在
                    this.isShow = true;
                    this.$refs.phoneEle.reset();
                    return;
                  }
                } else {
                  if (!res.data.isExist) {
                    this.isShow = true;
                    this.$refs.phoneEle.reset();
                    return;
                  }
                }
                this.click = this.checked ? true : false;
                localStorage.setItem("step1", this.ruleForm.phone); //临时存本地
                this.disable = true;
              } else {
                this.$refs.phoneEle.reset();
              }
            })
            .catch((error) => {
              this.$refs.phoneEle.reset();
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#step1 {
  .login-msg {
    margin-left: 120px;
  }
  .login-button {
    width: 74px;
    background: #00a0e9;
    color: #fff;
    padding: 5px 0;
    text-align: center;
    font-size: 14px;
    box-shadow: 0 17px 42px -12px rgba(10, 169, 238, 0.3);
    border-radius: 2px;
    margin: 32px 0 25px;
  }
  .login-checkbox {
    font-size: 14px;
    color: rgba(55, 69, 103, 0.85);
  }
  .other-login {
    font-size: 14px;
    color: rgba(85, 97, 126, 1);
    .wx-login-icon {
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-left: 16px;
      background: url("../../assets/img/login_wx_icon.png") center center;
      background-size: 100% 100%;
      vertical-align: middle;
    }
    .wx-login-icon:hover {
      background: url("../../assets/img/login_wx.png") center center;
      background-size: 100% 100%;
    }
  }
  .disable {
    opacity: 0.5;
  }
  /deep/.el-form-item__label {
    width: 120px !important;
  }
  /deep/.el-form-item__content {
    margin-left: 120px !important;
  }
}
</style>